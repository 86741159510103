import { Box, Typography, useTheme, IconButton, Stack } from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useState, useEffect } from "react";
import { API_URL } from "../../data/AppConstants";
import { useAuth } from "../../context/AuthContext";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import VerifiedIcon from "@mui/icons-material/Verified";
import PendingIcon from "@mui/icons-material/Pending";
import CheckIcon from "@mui/icons-material/Check";

const AprobarAgentes = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [agentes, setAgentes] = useState([]);
  const { isMobile } = useAuth();
  const [state, setState] = useState({
    open: false,
    message: "Snack",
    type: "success",
  });

  const showToast = (message, type) => {
    setState({ open: true, message, type });
  };

  const handleCloseToast = () => {
    setState({ ...state, open: false });
  };

  const fetchUsersData = () => {
    fetch(`${API_URL}auth/panel/new-agents`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.ok) {
          setAgentes(data.agents);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const approve = (userId) => {
    fetch(`${API_URL}auth/panel/approve-agent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          showToast(data.mssg, "success");
          fetchUsersData();
        } else {
          showToast(data.mssg, "error");
        }
      })
      .catch((err) => {
        console.log(err);
        showToast("Error en el servidor, intentelo más tarde", "error");
      });
  };

  const columns = [
    { field: "id", headerName: "ID", flex: isMobile ? 1 : "none" },
    {
      field: "name",
      headerName: "Nombre",
      flex: isMobile ? "none" : 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row }) => {
        return <Typography>{`${row.name} ${row.lastname}`}</Typography>;
      },
    },
    {
      field: "email",
      headerName: "Correo",
      flex: isMobile ? "none" : 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "country",
      headerName: "Pais",
      flex: isMobile ? "none" : 1,
    },
    {
      field: "state",
      headerName: "Provincia",
      flex: isMobile ? "none" : 1,
    },
    {
      field: "aprobado",
      headerName: "Aprobado",
      flex: isMobile ? "none" : 1,
      renderCell: ({ row }) => {
        return row.aprobado === 1 ? (
          <VerifiedIcon
            style={{
              color: colors.greenAccent[500],
            }}
          />
        ) : (
          <PendingIcon
            style={{
              color: colors.redAccent[500],
            }}
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "Acciones",
      headerAlign: "center",
      align: "center",
      width: 160,
      renderCell: ({ row }) => {
        return (
          <Box display="flex" justifyContent="center" alignItems="center">
            {row.aprobado !== 1 && (
              <IconButton
                onClick={() => {
                  approve(row.id);
                }}
                color="secondary"
                aria-label="Aprobar"
              >
                <CheckIcon />
              </IconButton>
            )}
          </Box>
        );
      },
    },
  ];
  useEffect(() => {
    fetchUsersData();
  }, []);

  return (
    <Box m="20px">
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={3000}
        open={state.open}
        onClose={handleCloseToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity={state.type}
          variant="outlined"
          sx={{ width: "100%" }}
        >
          {state.message}
        </Alert>
      </Snackbar>
      <Header
        title="Agentes Nuevos"
        subtitle="Aqui estan los datos de los agentes que crearon sus cuentas y estan pendientes de aprobacion"
      />

      <Box
        m="10px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            color: `${colors.primary[900]} !important`,
            fontWeight: 700,
            fontSize: "14px",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiTablePagination-toolbar": {
            color: "#000 !important",
          },
          "& .MuiDataGrid-selectedRowCount": {
            color: "#000 !important",
          },
        }}
      >
        <DataGrid
          rows={agentes}
          columns={columns}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          components={{
            NoResultsOverlay: () => (
              <Stack
                height="100%"
                alignItems="center"
                justifyContent="center"
                zIndex="999"
                position="relative"
              >
                No hay resultados para el filtro aplicado
              </Stack>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default AprobarAgentes;
