import {
  Box,
  Typography,
  useTheme,
  IconButton,
  Stack,
} from "@mui/material";
import { DataGrid, esES, GridToolbarFilterButton } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import esLocale from "dayjs/locale/es";

import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ModalUser from "../../components/player/ModalUser";
import Modal from "@mui/material/Modal";
import { useAuth } from "../../context/AuthContext";
import AlertDialog from "../../components/ChooseDialog";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import * as XLSX from "xlsx";
import LockResetIcon from "@mui/icons-material/LockReset";
import ModalChangePass from "../../components/player/ModalChangePass";
import {
  API_URL,
  countries,
} from "../../data/AppConstants";
dayjs.locale(esLocale);
const Team = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [usuarios, setUsuarios] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userToEdit, setUserToEdit] = useState([]);
  const [shouldOpenModal, setShouldOpenModal] = useState(false);
  const { isMobile } = useAuth();
  const [confirmDial, setConfirmDial] = useState(false);
  const [idToDelete, setIdToDelete] = useState(0);
  const [state, setState] = useState({
    open: false,
    message: "Snack",
    type: "success",
  });

  const [userToChange, setUserToChange] = useState(0);
  const [openModalChange, setOpenModalChange] = useState(false);
  const showToast = (message, type) => {
    setState({ open: true, message, type });
  };

  const handleCloseToast = () => {
    setState({ ...state, open: false });
  };
  function exportToExcel(data) {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Jugadores");

    XLSX.writeFile(workbook, "jugadores.xlsx");
  }

  useEffect(() => {
    if (shouldOpenModal && userToEdit) {
      handleOpenModal();
      setShouldOpenModal(false);
    }
  }, [shouldOpenModal, userToEdit]);

  const handleEdit = (id) => {
    const userFilter = usuarios.filter((user) => user.user_id === id);
    setUserToEdit(userFilter[0]);
    setShouldOpenModal(true);
  };

  const handleChangePass = (id) => {
    setUserToChange(id);
    setOpenModalChange(true);
  };

  const fetchUsersData = () => {
    fetch(`${API_URL}auth/players`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.ok) {
          setUsuarios(data.users);
        } else {
          setUsuarios([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = () => {
    setConfirmDial(false);
    fetch(`${API_URL}auth/player/${idToDelete}`, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          showToast("Jugador eliminado exitosamente!", "success");
          fetchUsersData();
        } else {
          showToast("Error en el servidor, intentelo más tarde", "error");
        }
      })
      .catch((err) => {
        showToast("Error en el servidor, intentelo más tarde", "error");
        console.log(err);
      });
  };
  const handleCloseDial = () => {
    setConfirmDial(false);
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchUsersData();
  };

  const handleCloseModalChange = () => {
    setOpenModalChange(false);
  };

  const columns = [
    { field: "id", headerName: "ID", flex: isMobile ? 1 : "none" },
    {
      field: "name",
      headerName: "Nombre",
      flex: isMobile ? "none" : 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row }) => {
        return <Typography>{`${row.name} ${row.lastname}`}</Typography>;
      },
    },
    {
      field: "email",
      headerName: "Correo",
      flex: isMobile ? "none" : 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "suscription",
      headerName: "Plan",
      flex: isMobile ? "none" : 1,
    },
    {
      field: "plan_anterior",
      headerName: "Plan anterior",
      flex: isMobile ? "none" : 1,
    },
    {
      field: "pais",
      headerName: "Pais",
      flex: isMobile ? "none" : 1,
      renderCell: ({ row }) => {
        return <Typography>{countries[row.pais] || "Desconocido"}</Typography>;
      },
    },
    {
      field: "club",
      headerName: "Club",
      flex: isMobile ? "none" : 1,
    },
    {
      field: "status",
      headerName: "Activo",
      headerAlign: "center",
      align: "center",
      flex: isMobile ? "none" : 1,
      renderCell: ({ row }) => {
        return row.status === 1 &&
          row.email_confirmed === 1 &&
          row.registro_completo === 1 ? (
          <Typography>Si</Typography>
        ) : (
          <Typography>No</Typography>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Fecha de registro",
      flex: isMobile ? "none" : 1,
      type: "date",
      filterOperators: [
        {
          label: "Es igual a",
          value: "is",
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) {
              return null;
            }
            return ({ value }) => {
              if (!value) {
                return false;
              }
              return (
                new Date(value).toDateString() ===
                new Date(filterItem.value).toDateString()
              );
            };
          },
          InputComponent: DatePickerInput,
        },
      ],

      renderCell: ({ row }) => {
        return (
          <Typography>
            {row.created_at.replace("T", " ").replace(".000Z", "")}
          </Typography>
        );
      },
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 160,
      renderCell: ({ row }) => {
        return (
          <Box display="flex" justifyContent="center" alignItems="center">
            <IconButton
              onClick={() => handleEdit(row.user_id)}
              color="secondary"
              aria-label="Editar"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setIdToDelete(row.user_id);
                setConfirmDial(true);
              }}
              color="secondary"
              aria-label="Eliminar"
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              onClick={() => handleChangePass(row.user_id)}
              color="secondary"
              aria-label="Cambiar contraseña"
            >
              <LockResetIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    fetchUsersData();
  }, []);

  function DatePickerInput(props) {
    const { item, applyValue, focusElementRef } = props;

    return (
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        locale={esLocale}
        adapterLocale="es"
      >
        <DemoContainer components={["DatePicker"]}>
          <DemoItem>
            <DatePicker
              value={item.value || null}
              onChange={(newValue) => {
                applyValue({ ...item, value: newValue });
              }}
              format="DD/MM/YYYY"
              localeText={{
                clearButtonLabel: "Vaciar",
                todayButtonLabel: "Hoy",
                okButtonLabel: "Guardar",
                cancelButtonLabel: "Cancelar",
                toolbarTitle: "Selecciona fecha y hora",
              }}
              slotProps={{
                color: "#fcfcfc",

                clearIcon: {
                  style: {
                    color: "#000000",
                  },
                },
              }}
            />
          </DemoItem>
        </DemoContainer>
      </LocalizationProvider>
    );
  }

  return (
    <Box m="20px">
      <Header title="Jugadores" subtitle="Gestiona los jugadores" />
      {
        <Modal open={isModalOpen} onClose={handleCloseModal}>
          <div>
            <ModalUser
              handleCloseModal={handleCloseModal}
              usuario={userToEdit}
            />
          </div>
        </Modal>
      }
      <Modal open={openModalChange} onClose={handleCloseModalChange}>
        <div>
          <ModalChangePass
            handleCloseModal={handleCloseModalChange}
            id={userToChange}
          />
        </div>
      </Modal>
      <Box
        width="220px"
        height="40px"
        m="5"
        p="5px"
        display="flex"
        gap="15px"
        justifyContent="center"
        alignItems="center"
        backgroundColor={colors.greenAccent[600]}
        fontSize="20px"
        borderRadius="4px"
        alignSelf="self-end"
        onClick={() => exportToExcel(usuarios)}
      >
        <IconButton color="primary" aria-label="Exportar a excel">
          <SaveAsIcon />
        </IconButton>
        <Typography variant="h4" color={colors.grey[900]} fontWeight="bold">
          Exportar Datos
        </Typography>
      </Box>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={3000}
        open={state.open}
        onClose={handleCloseToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity={state.type}
          variant="outlined"
          sx={{ width: "100%" }}
        >
          {state.message}
        </Alert>
      </Snackbar>
      <AlertDialog
        open={confirmDial}
        handleClose={handleCloseDial}
        title={"¿Está seguro de borrar este Jugador?"}
        content={
          "Se borraran todos los datos asociados a el y no podra recuperarlos"
        }
        handleConfirm={handleDelete}
      />
      <Box
        m="10px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            color: `${colors.primary[900]} !important`,
            fontWeight: 700,
            fontSize: "14px",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiTablePagination-toolbar": {
            color: "#000 !important",
          },
          "& .MuiDataGrid-selectedRowCount": {
            color: "#000 !important",
          },
        }}
      >
        <DataGrid
          rows={usuarios}
          columns={columns}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          components={{
            Toolbar: GridToolbarFilterButton,
            NoResultsOverlay: () => (
              <Stack
                height="100%"
                alignItems="center"
                justifyContent="center"
                zIndex="999"
                position="relative"
              >
                No hay resultados para el filtro aplicado
              </Stack>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default Team;
