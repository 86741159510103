import {
  Box,
  Typography,
  useTheme,
  IconButton,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { API_URL } from "../../data/AppConstants";
import { useAuth } from "../../context/AuthContext";
import AlertDialog from "../../components/ChooseDialog";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const Orders = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [orders, setOrders] = useState([]);
  const { isMobile } = useAuth();
  const [confirmDial, setConfirmDial] = useState(false);
  const [state, setState] = useState({
    open: false,
    message: "Snack",
    type: "success",
  });
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const currentYear = new Date().getFullYear();
  const yearOptions = [];
  for (let year = 2024; year <= currentYear; year++) {
    yearOptions.push(
      <MenuItem key={year} value={year}>
        {year}
      </MenuItem>
    );
  }

  const downloadSinglePDF = (pdfUrl, id) => {
    const invoiceId = id.toString().padStart(8, "0");
    fetch(pdfUrl)
      .then((response) => response.blob())
      .then((blob) => saveAs(blob, `order-${invoiceId}.pdf`))
      .catch((error) => console.error("Error downloading PDF:", error));
  };

  const downloadAllPDFs = () => {
    const zip = new JSZip();

    orders.forEach((order, index) => {
      fetch(order.factura)
        .then((response) => response.blob())
        .then((blob) => {
          zip.file(`order_${order.id}.pdf`, blob);
          if (index === orders.length - 1) {
            zip.generateAsync({ type: "blob" }).then((content) => {
              saveAs(content, "orders.zip");
            });
          }
        })
        .catch((error) => console.error("Error downloading PDF:", error));
    });
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };
  const showToast = (message, type) => {
    setState({ open: true, message, type });
  };

  const handleCloseToast = () => {
    setState({ ...state, open: false });
  };

  const fecthOrders = () => {
    fetch(`${API_URL}auth/all-orders`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ month: selectedMonth, year: selectedYear }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.ok) {
          setOrders(data.orders);
        } else {
          setOrders([]);
        }
      })
      .catch((err) => {
        setOrders([]);
        console.log(err);
      });
  };

  const handleCloseDial = () => {
    setConfirmDial(false);
  };

  const columns = [
    { field: "id", headerName: "ID", flex: isMobile ? 1 : "none" },
    {
      field: "description",
      headerName: "Descripcion",
      flex: isMobile ? "none" : 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "amount",
      headerName: "Cantidad",
      flex: isMobile ? "none" : 1,
      headerAlign: "left",
      align: "left",
      renderCell: ({ row }) => {
        return <Typography>{`€ ${row.amount}`}</Typography>;
      },
    },
    {
      field: "name",
      headerName: "Usuario",
      flex: isMobile ? "none" : 1,
      headerAlign: "left",
      align: "left",
      renderCell: ({ row }) => {
        return <Typography>{`${row.name}  ${row.lastname}`}</Typography>;
      },
    },
    {
      field: "fecha",
      headerName: "Fecha",
      flex: isMobile ? "none" : 1,
      renderCell: ({ row }) => {
        const fecha = row.fecha.replace(".000Z", "").split("T");
        return <Typography>{`${fecha[0]} ${fecha[1]}`}</Typography>;
      },
    },
    {
      field: "payment_method",
      headerName: "Metodo de pago",
      flex: isMobile ? "none" : 1,
    },
    {
      field: "status",
      headerName: "Estado",
      flex: isMobile ? "none" : 1,
    },
    {
      field: "actions",
      headerName: "Acciones",
      headerAlign: "center",
      align: "center",
      width: 160,
      renderCell: ({ row }) => {
        return (
          <IconButton
            onClick={() => {
              downloadSinglePDF(row.factura, row.id);
            }}
            color="secondary"
            aria-label="Descargar"
          >
            <FileDownloadIcon />
          </IconButton>
        );
      },
    },
  ];

  useEffect(() => {
    fecthOrders();
  }, []);

  useEffect(() => {
    fecthOrders();
  }, [selectedMonth, selectedYear]);

  return (
    <Box m="20px">
      <Header title="Pedidos" subtitle="Revisa los pedidos" />

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={3000}
        open={state.open}
        onClose={handleCloseToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity={state.type}
          variant="outlined"
          sx={{ width: "100%" }}
        >
          {state.message}
        </Alert>
      </Snackbar>
      <AlertDialog
        open={confirmDial}
        handleClose={handleCloseDial}
        title={"¿Está seguro de borrar este Jugador?"}
        content={
          "Se borraran todos los datos asociados a el y no podra recuperarlos"
        }
        handleConfirm={() => {}}
      />
      <Box
        m="5"
        p="5px"
        display="flex"
        gap="15px"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          width="260px"
          height="40px"
          m="5"
          p="5px"
          display="flex"
          gap="15px"
          justifyContent="center"
          alignItems="center"
          backgroundColor={colors.greenAccent[600]}
          fontSize="20px"
          borderRadius="4px"
          alignSelf="self-end"
          onClick={() => downloadAllPDFs()}
        >
          <IconButton color="primary" aria-label="Descargar facturas">
            <FileDownloadIcon />
          </IconButton>
          <Typography variant="h4" color={colors.grey[900]} fontWeight="bold">
            Descargar Facturas
          </Typography>
        </Box>
        <Box display="flex" gap="15px">
          <Select
            value={selectedMonth}
            onChange={handleMonthChange}
            displayEmpty
            inputProps={{ "aria-label": "Seleccione un mes" }}
            sx={{
              height: "35px",
            }}
          >
            <MenuItem value="">Mes</MenuItem>
            <MenuItem value={1}>Enero</MenuItem>
            <MenuItem value={2}>Febrero</MenuItem>
            <MenuItem value={3}>Marzo</MenuItem>
            <MenuItem value={4}>Abril</MenuItem>
            <MenuItem value={5}>Mayo</MenuItem>
            <MenuItem value={6}>Junio</MenuItem>
            <MenuItem value={7}>Julio</MenuItem>
            <MenuItem value={8}>Agosto</MenuItem>
            <MenuItem value={9}>Septiembre</MenuItem>
            <MenuItem value={10}>Octubre</MenuItem>
            <MenuItem value={11}>Noviembre</MenuItem>
            <MenuItem value={12}>Diciembre</MenuItem>
          </Select>
          <Select
            value={selectedYear}
            onChange={handleYearChange}
            displayEmpty
            inputProps={{ "aria-label": "Seleccione un año" }}
            sx={{
              height: "35px",
            }}
          >
            <MenuItem value="">Año</MenuItem>
            {yearOptions}
          </Select>
        </Box>
      </Box>
      <Box
        m="10px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            color: `${colors.primary[900]} !important`,
            fontWeight: 700,
            fontSize: "14px",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiTablePagination-toolbar": {
            color: "#000 !important",
          },
          "& .MuiDataGrid-selectedRowCount": {
            color: "#000 !important",
          },
        }}
      >
        <DataGrid
          rows={orders}
          columns={columns}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          components={{
            NoResultsOverlay: () => (
              <Stack
                height="100%"
                alignItems="center"
                justifyContent="center"
                zIndex="999"
                position="relative"
              >
                No hay resultados para el filtro aplicado
              </Stack>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default Orders;
