export const API_URL = "https://back.xn--br-6ja.com/";
//export const API_URL = "http://localhost:8080/";
export const plans = ["Basic", "Gold", "Platinum", "Unlimited"];
export const countries = {
  spain: "España",
  usa: "United States",
  france: "France",
  italy: "Italia",
  germany: "Deutschland",
};

export const provincesByCountry = {
  spain: {
    "A Coruña": "A Coruña",
    Álava: "Álava",
    Albacete: "Albacete",
    Alicante: "Alicante",
    Almería: "Almería",
    Asturias: "Asturias",
    Ávila: "Ávila",
    Badajoz: "Badajoz",
    Baleares: "Baleares",
    Barcelona: "Barcelona",
    Burgos: "Burgos",
    Cáceres: "Cáceres",
    Cádiz: "Cádiz",
    Cantabria: "Cantabria",
    Castellón: "Castellón",
    Ceuta: "Ceuta",
    "Ciudad Real": "Ciudad Real",
    Córdoba: "Córdoba",
    Cuenca: "Cuenca",
    Girona: "Girona",
    Granada: "Granada",
    Guadalajara: "Guadalajara",
    Gipuzkoa: "Gipuzkoa",
    Huelva: "Huelva",
    Huesca: "Huesca",
    Jaén: "Jaén",
    "La Rioja": "La Rioja",
    "Las Palmas": "Las Palmas",
    León: "León",
    Lleida: "Lleida",
    Lugo: "Lugo",
    Madrid: "Madrid",
    Málaga: "Málaga",
    Melilla: "Melilla",
    Murcia: "Murcia",
    Navarra: "Navarra",
    Ourense: "Ourense",
    Palencia: "Palencia",
    Pontevedra: "Pontevedra",
    Salamanca: "Salamanca",
    Segovia: "Segovia",
    Sevilla: "Sevilla",
    Soria: "Soria",
    Tarragona: "Tarragona",
    "Santa Cruz de Tenerife": "Santa Cruz de Tenerife",
    Teruel: "Teruel",
    Toledo: "Toledo",
    Valencia: "Valencia",
    Valladolid: "Valladolid",
    Vizcaya: "Vizcaya",
    Zamora: "Zamora",
    Zaragoza: "Zaragoza",
  },
  usa: {
    Alabama: "Alabama",
    Alaska: "Alaska",
    Arizona: "Arizona",
    Arkansas: "Arkansas",
    California: "California",
    Colorado: "Colorado",
    Connecticut: "Connecticut",
    Delaware: "Delaware",
    "District of Columbia": "District of Columbia",
    Florida: "Florida",
    Georgia: "Georgia",
    Hawaii: "Hawaii",
    Idaho: "Idaho",
    Illinois: "Illinois",
    Indiana: "Indiana",
    Iowa: "Iowa",
    Kansas: "Kansas",
    Kentucky: "Kentucky",
    Louisiana: "Louisiana",
    Maine: "Maine",
    Maryland: "Maryland",
    Massachusetts: "Massachusetts",
    Michigan: "Michigan",
    Minnesota: "Minnesota",
    Mississippi: "Mississippi",
    Missouri: "Missouri",
    Montana: "Montana",
    Nebraska: "Nebraska",
    Nevada: "Nevada",
    "New Hampshire": "New Hampshire",
    "New Jersey": "New Jersey",
    "New Mexico": "New Mexico",
    "New York": "New York",
    "North Carolina": "North Carolina",
    "North Dakota": "North Dakota",
    Ohio: "Ohio",
    Oklahoma: "Oklahoma",
    Oregon: "Oregon",
    Pennsylvania: "Pennsylvania",
    "Rhode Island": "Rhode Island",
    "South Carolina": "South Carolina",
    "South Dakota": "South Dakota",
    Tennessee: "Tennessee",
    Texas: "Texas",
    Utah: "Utah",
    Vermont: "Vermont",
    Virginia: "Virginia",
    Washington: "Washington",
    "West Virginia": "West Virginia",
    Wisconsin: "Wisconsin",
    Wyoming: "Wyoming",
  },
  france: {
    Ain: "Ain",
    Aisne: "Aisne",
    Allier: "Allier",
    "Alpes-de-Haute-Provence": "Alpes-de-Haute-Provence",
    "Hautes-Alpes": "Hautes-Alpes",
    "Alpes-Maritimes": "Alpes-Maritimes",
    Ardèche: "Ardèche",
    Ardennes: "Ardennes",
    Ariège: "Ariège",
    Aube: "Aube",
    Aude: "Aude",
    Aveyron: "Aveyron",
    "Bouches-du-Rhône": "Bouches-du-Rhône",
    Calvados: "Calvados",
    Cantal: "Cantal",
    Charente: "Charente",
    "Charente-Maritime": "Charente-Maritime",
    Cher: "Cher",
    Corrèze: "Corrèze",
    "Côte-d'Or": "Côte-d'Or",
    "Côtes-d'Armor": "Côtes-d'Armor",
    Creuse: "Creuse",
    Dordogne: "Dordogne",
    Doubs: "Doubs",
    Drôme: "Drôme",
    Eure: "Eure",
    "Eure-et-Loir": "Eure-et-Loir",
    Finistère: "Finistère",
    "Corse-du-Sud": "Corse-du-Sud",
    "Haute-Corse": "Haute-Corse",
    Gard: "Gard",
    "Haute-Garonne": "Haute-Garonne",
    Gers: "Gers",
    Gironde: "Gironde",
    Hérault: "Hérault",
    "Ille-et-Vilaine": "Ille-et-Vilaine",
    Indre: "Indre",
    "Indre-et-Loire": "Indre-et-Loire",
    Isère: "Isère",
    Jura: "Jura",
    Landes: "Landes",
    "Loir-et-Cher": "Loir-et-Cher",
    Loire: "Loire",
    "Haute-Loire": "Haute-Loire",
    "Loire-Atlantique": "Loire-Atlantique",
    Loiret: "Loiret",
    Lot: "Lot",
    "Lot-et-Garonne": "Lot-et-Garonne",
    Lozère: "Lozère",
    "Maine-et-Loire": "Maine-et-Loire",
    Manche: "Manche",
    Marne: "Marne",
    "Haute-Marne": "Haute-Marne",
    Mayenne: "Mayenne",
    "Meurthe-et-Moselle": "Meurthe-et-Moselle",
    Meuse: "Meuse",
    Morbihan: "Morbihan",
    Moselle: "Moselle",
    Nièvre: "Nièvre",
    Nord: "Nord",
    Oise: "Oise",
    Orne: "Orne",
    "Pas-de-Calais": "Pas-de-Calais",
    "Puy-de-Dôme": "Puy-de-Dôme",
    "Pyrénées-Atlantiques": "Pyrénées-Atlantiques",
    "Hautes-Pyrénées": "Hautes-Pyrénées",
    "Pyrénées-Orientales": "Pyrénées-Orientales",
    "Bas-Rhin": "Bas-Rhin",
    "Haut-Rhin": "Haut-Rhin",
    Rhône: "Rhône",
    "Haute-Saône": "Haute-Saône",
    "Saône-et-Loire": "Saône-et-Loire",
    Sarthe: "Sarthe",
    Savoie: "Savoie",
    "Haute-Savoie": "Haute-Savoie",
    Paris: "Paris",
    "Seine-Maritime": "Seine-Maritime",
    "Seine-et-Marne": "Seine-et-Marne",
    Yvelines: "Yvelines",
    "Deux-Sèvres": "Deux-Sèvres",
    Somme: "Somme",
    Tarn: "Tarn",
    "Tarn-et-Garonne": "Tarn-et-Garonne",
    Var: "Var",
    Vaucluse: "Vaucluse",
    Vendée: "Vendée",
    Vienne: "Vienne",
    "Haute-Vienne": "Haute-Vienne",
    Vosges: "Vosges",
    Yonne: "Yonne",
    "Territoire de Belfort": "Territoire de Belfort",
    Essonne: "Essonne",
    "Hauts-de-Seine": "Hauts-de-Seine",
    "Seine-Saint-Denis": "Seine-Saint-Denis",
    "Val-de-Marne": "Val-de-Marne",
    "Val-d'Oise": "Val-d'Oise",
  },
  italy: {
    Agrigento: "Agrigento",
    Alessandria: "Alessandria",
    Ancona: "Ancona",
    Aosta: "Aosta",
    Arezzo: "Arezzo",
    "Ascoli Piceno": "Ascoli Piceno",
    Asti: "Asti",
    Avellino: "Avellino",
    Bari: "Bari",
    "Barletta-Andria-Trani": "Barletta-Andria-Trani",
    Belluno: "Belluno",
    Benevento: "Benevento",
    Bergamo: "Bergamo",
    Biella: "Biella",
    Bologna: "Bologna",
    Bolzano: "Bolzano",
    Brescia: "Brescia",
    Brindisi: "Brindisi",
    Cagliari: "Cagliari",
    Caltanissetta: "Caltanissetta",
    Campobasso: "Campobasso",
    "Carbonia-Iglesias": "Carbonia-Iglesias",
    Caserta: "Caserta",
    Catania: "Catania",
    Catanzaro: "Catanzaro",
    Chieti: "Chieti",
    Como: "Como",
    Cosenza: "Cosenza",
    Cremona: "Cremona",
    Crotone: "Crotone",
    Cuneo: "Cuneo",
    Enna: "Enna",
    Fermo: "Fermo",
    Ferrara: "Ferrara",
    Firenze: "Firenze",
    Foggia: "Foggia",
    "Forlì-Cesena": "Forlì-Cesena",
    Frosinone: "Frosinone",
    Genova: "Genova",
    Gorizia: "Gorizia",
    Grosseto: "Grosseto",
    Imperia: "Imperia",
    Isernia: "Isernia",
    "La Spezia": "La Spezia",
    "L'Aquila": "L'Aquila",
    Latina: "Latina",
    Lecce: "Lecce",
    Lecco: "Lecco",
    Livorno: "Livorno",
    Lodi: "Lodi",
    Lucca: "Lucca",
    Macerata: "Macerata",
    Mantova: "Mantova",
    "Massa-Carrara": "Massa-Carrara",
    Matera: "Matera",
    "Medio Campidano": "Medio Campidano",
    Messina: "Messina",
    Milano: "Milano",
    Modena: "Modena",
    "Monza e della Brianza": "Monza e della Brianza",
    Napoli: "Napoli",
    Novara: "Novara",
    Nuoro: "Nuoro",
    Ogliastra: "Ogliastra",
    "Olbia-Tempio": "Olbia-Tempio",
    Oristano: "Oristano",
    Padova: "Padova",
    Palermo: "Palermo",
    Parma: "Parma",
    Pavia: "Pavia",
    Perugia: "Perugia",
    "Pesaro e Urbino": "Pesaro e Urbino",
    Pescara: "Pescara",
    Piacenza: "Piacenza",
    Pisa: "Pisa",
    Pistoia: "Pistoia",
    Pordenone: "Pordenone",
    Potenza: "Potenza",
    Prato: "Prato",
    Ragusa: "Ragusa",
    Ravenna: "Ravenna",
    "Reggio Calabria": "Reggio Calabria",
    "Reggio Emilia": "Reggio Emilia",
    Rieti: "Rieti",
    Rimini: "Rimini",
    Roma: "Roma",
    Rovigo: "Rovigo",
    Salerno: "Salerno",
    Sassari: "Sassari",
    Savona: "Savona",
    Siena: "Siena",
    Siracusa: "Siracusa",
    Sondrio: "Sondrio",
    Taranto: "Taranto",
    Teramo: "Teramo",
    Terni: "Terni",
    Torino: "Torino",
    Trapani: "Trapani",
    Trento: "Trento",
    Treviso: "Treviso",
    Trieste: "Trieste",
    Udine: "Udine",
    Varese: "Varese",
    Venezia: "Venezia",
    "Verbano-Cusio-Ossola": "Verbano-Cusio-Ossola",
    Vercelli: "Vercelli",
    Verona: "Verona",
    "Vibo Valentia": "Vibo Valentia",
    Vicenza: "Vicenza",
    Viterbo: "Viterbo",
  },
  germany: {
    "Baden-Württemberg": "Baden-Württemberg",
    Bavaria: "Bavaria",
    Berlin: "Berlin",
    Brandenburg: "Brandenburg",
    Bremen: "Bremen",
    Hamburg: "Hamburg",
    Hesse: "Hesse",
    "Lower Saxony": "Lower Saxony",
    "Mecklenburg-Vorpommern": "Mecklenburg-Vorpommern",
    "North Rhine-Westphalia": "North Rhine-Westphalia",
    "Rhineland-Palatinate": "Rhineland-Palatinate",
    Saarland: "Saarland",
    Saxony: "Saxony",
    "Saxony-Anhalt": "Saxony-Anhalt",
    "Schleswig-Holstein": "Schleswig-Holstein",
    Thuringia: "Thuringia",
  },
};

export const categorias = {
  prebenjamin: "PreBenjamín",
  benjamin: "Benjamín",
  infantil: "Infantil",
  cadete: "Cadete",
  juvenil: "Juvenil",
  sub23: "Sub-23",
  senior: "Senior",
};

export const modulos = [
  "Panel de control",
  "Jugadores",
  "Agentes",
  "Pedidos",
  "Videos",
  "Verificaciones",
  "Verificaciones Afiliados",
  "Afiliados",
  "Consultas",
  "Agentes Nuevos",
  "Cupones",
  "Retiros",
  "Correo",
  "Usuarios panel",
];
export const posiciones = {
  goalKeeper: "Portero",
  LD: "Lateral Derecho",
  LI: "Lateral Izquierdo",
  DFD: "Defensa Central Derecho",
  DFI: "Defensa Central Izquierdo",
  MCD: "Mediocampista Defensivo",
  MDD: "Mediocampista Derecho",
  MDI: "Mediocampista Central",
  DC: "Delantero Centro",
  MCO: "Mediocampista Ofensivo",
  EXT: "Extremo Izquierdo",
};

export const selecciones = {
  male: "Masculina",
  female: "Femenina",
};
export const piesDominantes = {
  left_feet: "Zurdo",
  right_feet: "Derecho",
  both_feet: "Ambidiestro",
};

export const nationalCategories = {
  male: { U15: "U15", U17: "U17", U19: "U19", U21: "U21" },
  female: { U17: "U17", U20: "U20" },
};
