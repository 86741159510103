import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {
  Typography,
  Box,
  useTheme,
  FormControl,
  InputLabel,
  Input,
  FormGroup,
  FormControlLabel,
  Grid,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import {
  API_URL,
  countries,
  provincesByCountry,
} from "../../data/AppConstants";
import { tokens } from "../../theme";
import { useAuth } from "../../context/AuthContext";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import esLocale from "dayjs/locale/es";
dayjs.locale(esLocale);

const ModalCreateAgent = ({ handleCloseModal }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { isMobile } = useAuth();
  const [state, setState] = useState({
    open: false,
    message: "Snack",
    type: "success",
  });

  const showToast = (message, type) => {
    setState({ open: true, message, type });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const [formValues, setFormValues] = useState({
    name: "",
    lastname: "",
    email: "",
    country: "spain",
    state: "",
    entidad_deportiva: "",
    date: dayjs(),
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleChangeDate = (value, newdate) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [value]: newdate,
    }));
  };

  const handleCreateAgent = () => {
    if (
      formValues.name === "" ||
      formValues.lastname === "" ||
      formValues.email === "" ||
      formValues.country === "" ||
      formValues.state === ""
    ) {
      showToast("Por favor, complete todos los campos.", "error");
    } else {
      fetch(`${API_URL}auth/panel/agent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formValues),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.ok) {
            showToast(data.message, "success");
            setTimeout(() => {
              handleCloseModal();
            }, 1000);
          } else {
            showToast("Error en el servidor, intentelo más tarde", "error");
          }
        })
        .catch((err) => {
          console.log(err);
          showToast("Error en el servidor, intentelo más tarde", "error");
        });
    }
  };
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: colors.primary[800],
        boxShadow: 24,
        p: 4,
        borderRadius: "8px",
        minWidth: "400px",
        overflowY: isMobile ? "scroll" : "none",
        height: isMobile ? "80%" : "auto",
      }}
    >
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={3000}
        open={state.open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant="outlined"
          sx={{ width: "100%" }}
        >
          {state.message}
        </Alert>
      </Snackbar>
      <Typography variant="h4" mb={2}>
        Crear Agente
      </Typography>
      <Grid container spacing={6} alignItems={"flex-start"}>
        <Grid item xs={12} sm={6} md={6}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
              }}
              htmlFor="name"
            >
              Nombre
            </InputLabel>
            <Input
              id="name"
              name="name"
              value={formValues.name}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
              }}
              htmlFor="lastname"
            >
              Apellidos
            </InputLabel>
            <Input
              id="lastname"
              name="lastname"
              value={formValues.lastname}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
              }}
              htmlFor="email"
            >
              Correo
            </InputLabel>
            <Input
              id="email"
              name="email"
              value={formValues.email}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
              }}
              htmlFor="entidad_deportiva"
            >
              Entidad Deportiva
            </InputLabel>
            <Input
              id="entidad_deportiva"
              name="entidad_deportiva"
              value={formValues.entidad_deportiva}
              onChange={handleChange}
            />
          </FormControl>

          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            locale={esLocale}
            adapterLocale="es"
          >
            <DemoContainer components={["DatePicker"]}>
              <DemoItem label="Fecha de nacimiento">
                <DatePicker
                  value={formValues.date}
                  onChange={(newValue) => handleChangeDate("date", newValue)}
                  format="DD/MM/YYYY"
                  localeText={{
                    clearButtonLabel: "Vaciar",
                    todayButtonLabel: "Hoy",
                    okButtonLabel: "Guardar",
                    cancelButtonLabel: "Cancelar",
                    toolbarTitle: "Selecciona fecha y hora",
                  }}
                  slotProps={{
                    color: "#fcfcfc",

                    clearIcon: {
                      style: {
                        color: "#000000",
                      },
                    },
                  }}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Box>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
                fontSize: "15px",
              }}
            >
              País
            </InputLabel>
            <FormControl fullWidth>
              <Select
                id="country"
                name="country"
                value={formValues.country}
                onChange={handleChange}
                variant="outlined"
              >
                {Object.keys(countries).map((countryKey) => (
                  <MenuItem
                    key={`pais-${countryKey}`}
                    value={countryKey}
                    style={{
                      backgroundColor:
                        formValues.country === countryKey
                          ? colors.greenAccent[500]
                          : "transparent",
                      color:
                        formValues.country === countryKey ? "#000" : "#fff",
                    }}
                  >
                    {countries[countryKey] || "Desconocido"}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
                fontSize: "15px",
              }}
            >
              Provincia
            </InputLabel>
            <FormControl fullWidth>
              <Select
                id="state"
                name="state"
                value={formValues.state}
                onChange={handleChange}
                variant="outlined"
              >
                {Object.keys(provincesByCountry[formValues.country]).map(
                  (state) => (
                    <MenuItem
                      key={`provincia-${state}`}
                      value={state}
                      style={{
                        backgroundColor:
                          formValues.state === state
                            ? colors.greenAccent[500]
                            : "transparent",
                        color: formValues.state === state ? "#000" : "#fff",
                      }}
                    >
                      {provincesByCountry[formValues.country][state]}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-around" mt={3}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCreateAgent}
            >
              Guardar
            </Button>
            <Button variant="contained" onClick={handleCloseModal}>
              Cerrar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ModalCreateAgent;
