import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {
  Typography,
  Box,
  useTheme,
  FormControl,
  InputLabel,
  Input,
  FormGroup,
  FormControlLabel,
  Grid,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import {
  API_URL,
  countries,
  provincesByCountry,
} from "../../data/AppConstants";
import { tokens } from "../../theme";
import { useAuth } from "../../context/AuthContext";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import esLocale from "dayjs/locale/es";
dayjs.locale(esLocale);

const ModalEditAgent = ({ handleCloseModal, agente }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { isMobile } = useAuth();
  const [state, setState] = useState({
    open: false,
    message: "Snack",
    type: "success",
  });
  console.log(agente);
  const [changePassword, setChangePassword] = useState(false);
  const handleChangePassword = () => {
    setChangePassword(true);
  };

  const showToast = (message, type) => {
    setState({ open: true, message, type });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const [formValues, setFormValues] = useState({
    userId: agente.user_id,
    Name: agente.name,
    LastName: agente.lastname,
    Email: agente.email,
    Pais: agente.country ?? "España",
    Provincia: agente.state,
    entidad_deportiva: agente.entidad_deportiva,
    Birthday: dayjs(agente.birthday),
    password: "",
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleChangeDate = (value, newdate) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [value]: newdate,
    }));
  };

  const handleCreateAgent = () => {
    if (
      formValues.Name === "" ||
      formValues.LastName === "" ||
      formValues.Email === "" ||
      formValues.Pais === "" ||
      formValues.Provincia === ""
    ) {
      showToast("Por favor, complete todos los campos.", "error");
    } else {
      fetch(`${API_URL}auth/edit-agent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formValues),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.ok) {
            showToast(data.message, "success");
            setTimeout(() => {
              handleCloseModal();
            }, 1000);
          } else {
            showToast("Error en el servidor, intentelo más tarde", "error");
          }
        })
        .catch((err) => {
          console.log(err);
          showToast("Error en el servidor, intentelo más tarde", "error");
        });
    }
  };
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: colors.primary[800],
        boxShadow: 24,
        p: 4,
        borderRadius: "8px",
        minWidth: "400px",
        overflowY: isMobile ? "scroll" : "none",
        height: isMobile ? "80%" : "auto",
      }}
    >
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={3000}
        open={state.open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant="outlined"
          sx={{ width: "100%" }}
        >
          {state.message}
        </Alert>
      </Snackbar>
      <Typography variant="h4" mb={2}>
        Editar Agente
      </Typography>
      <Grid container spacing={6} alignItems={"flex-start"}>
        <Grid item xs={12} sm={6} md={6}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
              }}
              htmlFor="Name"
            >
              Nombre
            </InputLabel>
            <Input
              id="Name"
              name="Name"
              value={formValues.Name}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
              }}
              htmlFor="LastName"
            >
              Apellidos
            </InputLabel>
            <Input
              id="LastName"
              name="LastName"
              value={formValues.LastName}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
              }}
              htmlFor="Email"
            >
              Correo
            </InputLabel>
            <Input
              id="Email"
              name="Email"
              value={formValues.Email}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
              }}
              htmlFor="entidad_deportiva"
            >
              Entidad Deportiva
            </InputLabel>
            <Input
              id="entidad_deportiva"
              name="entidad_deportiva"
              value={formValues.entidad_deportiva}
              onChange={handleChange}
            />
          </FormControl>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            locale={esLocale}
            adapterLocale="es"
          >
            <DemoContainer components={["DatePicker"]}>
              <DemoItem label="Fecha de nacimiento">
                <DatePicker
                  value={formValues.Birthday}
                  onChange={(newValue) =>
                    handleChangeDate("Birthday", newValue)
                  }
                  format="DD/MM/YYYY"
                  localeText={{
                    clearButtonLabel: "Vaciar",
                    todayButtonLabel: "Hoy",
                    okButtonLabel: "Guardar",
                    cancelButtonLabel: "Cancelar",
                    toolbarTitle: "Selecciona fecha y hora",
                  }}
                  slotProps={{
                    color: "#fcfcfc",

                    clearIcon: {
                      style: {
                        color: "#000000",
                      },
                    },
                  }}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Box>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
                fontSize: "15px",
              }}
            >
              País
            </InputLabel>
            <FormControl fullWidth>
              <Select
                id="Pais"
                name="Pais"
                value={formValues.Pais}
                onChange={handleChange}
                variant="outlined"
              >
                {Object.keys(countries).map((countryKey) => (
                  <MenuItem
                    key={`pais-${countryKey}`}
                    value={countryKey}
                    style={{
                      backgroundColor:
                        formValues.Pais === countryKey
                          ? colors.greenAccent[500]
                          : "transparent",
                      color: formValues.Pais === countryKey ? "#000" : "#fff",
                    }}
                  >
                    {countries[countryKey] || "Desconocido"}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
                fontSize: "15px",
              }}
            >
              Provincia
            </InputLabel>
            <FormControl fullWidth>
              <Select
                id="Provincia"
                name="Provincia"
                value={formValues.Provincia}
                onChange={handleChange}
                variant="outlined"
              >
                {provincesByCountry[formValues.Pais]
                  ? Object.keys(provincesByCountry[formValues.Pais]).map(
                      (state) => (
                        <MenuItem
                          key={`provincia-${state}`}
                          value={state}
                          style={{
                            backgroundColor:
                              formValues.Provincia === state
                                ? colors.greenAccent[500]
                                : "transparent",
                            color:
                              formValues.Provincia === state ? "#000" : "#fff",
                          }}
                        >
                          {provincesByCountry[formValues.Pais][state]}
                        </MenuItem>
                      )
                    )
                  : formValues.Pais === "" ||
                    !provincesByCountry[formValues.Pais]
                  ? Object.keys(provincesByCountry["spain"]).map((state) => (
                      <MenuItem
                        key={`provincia-${state}`}
                        value={state}
                        style={{
                          backgroundColor:
                            formValues.Provincia === state
                              ? colors.greenAccent[500]
                              : "transparent",
                          color:
                            formValues.Provincia === state ? "#000" : "#fff",
                        }}
                      >
                        {provincesByCountry["spain"][state]}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
          </Box>
          {!changePassword && (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleChangePassword}
              sx={{ mb: 2, mt: 2 }}
            >
              Cambiar Contraseña
            </Button>
          )}
          {changePassword && (
            <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
              <InputLabel
                sx={{
                  "&.Mui-focused": {
                    color: "#ffffffb3",
                  },
                }}
                htmlFor="password"
              >
                Contraseña
              </InputLabel>
              <Input
                id="password"
                name="password"
                type="password"
                value={formValues.password}
                onChange={handleChange}
              />
            </FormControl>
          )}
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-around" mt={3}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCreateAgent}
            >
              Guardar
            </Button>
            <Button variant="contained" onClick={handleCloseModal}>
              Cerrar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ModalEditAgent;
