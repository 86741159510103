import Header from "../../components/Header";
import { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {
  Typography,
  Box,
  useTheme,
  FormControl,
  InputLabel,
  Input,
  Button,
  IconButton,
} from "@mui/material";
import { API_URL } from "../../data/AppConstants";
import { tokens } from "../../theme";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useParams } from "react-router-dom";
import esLocale from "dayjs/locale/es";
import CloseIcon from "@mui/icons-material/Close";

dayjs.locale(esLocale);

const CreatePlayer = () => {
  const theme = useTheme();
  const [isMobile, setIsMobile] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);
  const [showAd, setShowAd] = useState(true);

  const colors = tokens(theme.palette.mode);
  let { referralCode } = useParams();
  const [state, setState] = useState({
    open: false,
    message: "Snack",
    type: "success",
  });

  const showToast = (message, type) => {
    setState({ open: true, message, type });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const handleCloseAd = () => {
    setShowAd(false);
  };
  const [formValues, setFormValues] = useState({
    Name: "",
    LastName: "",
    Email: "",
    DNI: "",
    CodigoAfiliado: referralCode ?? "",
    Birthday: dayjs(),
    Password: "",
    Direccion: "",
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleChangeDate = (value, newdate) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [value]: newdate,
    }));
  };

  const handleCreateAgent = () => {
    if (
      formValues.Name === "" ||
      formValues.LastName === "" ||
      formValues.Email === "" ||
      formValues.DNI === "" ||
      formValues.Direccion === "" ||
      formValues.Password === ""
    ) {
      showToast("Por favor, complete todos los campos.", "error");
    } else {
      fetch(`${API_URL}auth/player`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formValues),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.ok) {
            setFormValues({
              Name: "",
              LastName: "",
              Email: "",
              DNI: "",
              CodigoAfiliado: referralCode ?? "",
              Birthday: dayjs(),
              Password: "",
              Direccion: "",
            });
            showToast(
              "Tu cuenta ha sido creada, ve a la app y completa el resto de informacion necesaria.",
              "success"
            );
          } else {
            showToast(data.error, "error");
          }
        })
        .catch((err) => {
          console.log(err);
          setFormValues({
            Name: "",
            LastName: "",
            Email: "",
            DNI: "",
            CodigoAfiliado: referralCode ?? "",
            Birthday: dayjs(),
            Password: "",
            Direccion: "",
          });
          showToast("Error en el servidor, intentelo más tarde", "error");
        });
    }
  };

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    setIsMobile(
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      )
    );
    setIsAndroid(/Android/i.test(userAgent));
  }, []);
  return (
    <>
      {isMobile && showAd && (
        <Alert
          severity="info"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleCloseAd}
            >
              <CloseIcon />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          <Box display="flex" alignItems="center">
            <img
              src="../../assets/ic_launcher.png"
              alt="Logo"
              width={40}
              height={40}
              style={{ marginRight: "10px" }}
            />
            <Typography variant="h6" component="span">
              Bró Football Platform
            </Typography>
          </Box>
          <Typography variant="body2" sx={{ mt: 1 }}>
            Calificación: 4.5
          </Typography>
          <Typography variant="body2">Descargas: 10,000+</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (isAndroid) {
                window.location.href =
                  "https://play.google.com/store/apps/details?id=your_app_id";
              } else {
                window.location.href =
                  "https://apps.apple.com/us/app/your-app-name/idyour_app_id";
              }
            }}
            sx={{ mt: 1 }}
          >
            Abrir
          </Button>
        </Alert>
      )}

      <Box
        m="20px auto"
        width="100%"
        maxWidth="500px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          autoHideDuration={3000}
          open={state.open}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={state.type}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {state.message}
          </Alert>
        </Snackbar>
        <img
          alt="profile-user"
          width="140px"
          height="auto"
          src={`../../assets/logo.svg`}
          style={{ cursor: "pointer", marginBottom: "20px" }}
        />
        <Header
          title="Registrate"
          subtitle="Encuentra el agente que te acercara a tus sueños."
        />
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel
            sx={{
              "&.Mui-focused": {
                color: "#ffffffb3",
              },
            }}
            htmlFor="Name"
          >
            Nombre
          </InputLabel>
          <Input
            id="Name"
            name="Name"
            value={formValues.Name}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel
            sx={{
              "&.Mui-focused": {
                color: "#ffffffb3",
              },
            }}
            htmlFor="LastName"
          >
            Apellidos
          </InputLabel>
          <Input
            id="LastName"
            name="LastName"
            value={formValues.LastName}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel
            sx={{
              "&.Mui-focused": {
                color: "#ffffffb3",
              },
            }}
            htmlFor="Email"
          >
            Correo
          </InputLabel>
          <Input
            id="Email"
            name="Email"
            value={formValues.Email}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel
            sx={{
              "&.Mui-focused": {
                color: "#ffffffb3",
              },
            }}
            htmlFor="DNI"
          >
            DNI
          </InputLabel>
          <Input
            id="DNI"
            name="DNI"
            value={formValues.DNI}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel
            sx={{
              "&.Mui-focused": {
                color: "#ffffffb3",
              },
            }}
            htmlFor="Direccion"
          >
            Dirección
          </InputLabel>
          <Input
            id="Direccion"
            name="Direccion"
            value={formValues.Direccion}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
          <InputLabel
            sx={{
              "&.Mui-focused": {
                color: "#ffffffb3",
              },
            }}
            htmlFor="Password"
          >
            Contraseña
          </InputLabel>
          <Input
            id="Password"
            name="Password"
            type="password"
            value={formValues.Password}
            onChange={handleChange}
          />
        </FormControl>
        <Box sx={{ width: "100%", mb: 2 }}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            locale={esLocale}
            adapterLocale="es"
          >
            <DemoContainer components={["DatePicker"]}>
              <DemoItem label="Fecha de nacimiento">
                <DatePicker
                  value={formValues.Birthday}
                  onChange={(newValue) =>
                    handleChangeDate("Birthday", newValue)
                  }
                  format="DD/MM/YYYY"
                  localeText={{
                    clearButtonLabel: "Vaciar",
                    todayButtonLabel: "Hoy",
                    okButtonLabel: "Guardar",
                    cancelButtonLabel: "Cancelar",
                    toolbarTitle: "Selecciona fecha y hora",
                  }}
                  slotProps={{
                    color: "#fcfcfc",

                    clearIcon: {
                      style: {
                        color: "#000000",
                      },
                    },
                  }}
                  style={{ width: 500 }}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
        </Box>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel
            sx={{
              "&.Mui-focused": {
                color: "#ffffffb3",
              },
            }}
            htmlFor="CodigoAfiliado"
          >
            Codigo de referido
          </InputLabel>
          <Input
            id="CodigoAfiliado"
            name="CodigoAfiliado"
            value={formValues.CodigoAfiliado}
            onChange={handleChange}
          />
        </FormControl>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleCreateAgent}
        >
          Crear
        </Button>
      </Box>
    </>
  );
};

export default CreatePlayer;
